:root {
  --blue: #1244f6;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Link */
a {
  color: var(--blue);
}

a:hover {
  color: var(--blue);
}

/* Button */
.btn {
  background: var(--blue);
  border: none;
  color: #fff;
}

.btn:hover {
  background: var(--blue);
  border: none;
  color: #fff;
  transform: scale(1.05);
}

.btn:focus {
  background: var(--blue);
  border: none;
  color: #fff;
}

.btn:active {
  background: var(--blue) !important;
  border: none !important;
}

/* Pagination */
.page-link {
  color: var(--blue);
}

.page-link:hover {
  color: var(--blue);
  box-shadow: none;
}

.page-item.active .page-link{
  background-color: var(--blue);
  border-color: var(--blue);
}

/* Toggle */
.custom-control-input:focus~.custom-control-label::before {
  border-color: var(--blue);
  box-shadow: none;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: var(--blue);
  background-color: var(--blue);
}

.custom-control-input:active~.custom-control-label::before {
  background-color: var(--blue);
  border-color: var(--blue);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: var(--blue);
}

.custom-control-input-green:not(:disabled):active~.custom-control-label::before {
  background-color: var(--blue);
  border-color: var(--blue);
}

.badge-orange {
  background-color: orangered;
  color: white;
}