.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* spacing */
.pt-6,
.py-6 {
  padding-top: 5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 5rem !important;
}

.pt-11,
.py-11 {
  padding-top: 10rem !important;
}

.pr-11,
.px-11 {
  padding-right: 10rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 10rem !important;
}

.pl-11,
.px-11 {
  padding-left: 10rem !important;
}

/* max-width sizing */

.mw-none {
  max-width: none;
}

.bg-deleted {
  background-color: #ff869247;
}

.text-sm {
  font-size: 0.875rem;
}

.text-base {
  font-size: 1rem;
}

.text-last-center {
  text-align-last: center;
}

.w-fit {
  width: fit-content;
}

.ml-60 {
  margin-left: -60px;
}

.mt-20 {
  margin-top: 20px;
}

.f-10-5 {
  font-size: 10.5px;
}

.rdrDateDisplayWrapper {
  font-size: x-small;
  z-index: 1060;
}

.rdrMonthAndYearWrapper {
  font-size: x-small;
  z-index: 1060;
}

.rdrMonths {
  font-size: x-small;
  z-index: 1060;
}

.page-item {
  z-index: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}